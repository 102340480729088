import axios from "axios"
import { config } from '../config'

export const sendEmailService = {
    sendEmail,
}

async function sendEmail(formdata) {
    const requestOptions = {
        headers: {"Accept":"application/json"}
    }
    console.log(formdata)
    const response = await axios.post(`${config.testUrl}/notification/api/send-mail`, formdata, requestOptions)
    return response
    // console.log(response)
    // const contactMail = response.data
    // console.log(contactMail)
    // if(contactMail.status === true){
    // // console.log(response)
    // }
}