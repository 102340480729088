import { sendEmailConstants } from "../constant/sendEmail";
import { sendEmailService } from "../service/sendEmail";

function sendEmailDetails(formdata) {
    return async dispatch => {
        dispatch(request(formdata))
        console.log(formdata)
        try {
          const contactMail = await sendEmailService.sendEmail(formdata)
          console.log(contactMail)
          dispatch(success(contactMail))
          return contactMail;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          return error?.response;
      }
    }

  function request (formdata) { return { type: sendEmailConstants.SENDEMAIL_REQUEST, formdata } }
  function success (contactMail) { return { type: sendEmailConstants.SENDEMAIL_SUCCESS, contactMail } }
  function failure (error) { return { type: sendEmailConstants.SENDEMAIL_ERROR, error } }
}


export const emailAction = {
  sendEmailDetails
}