import React from 'react'
import {
  PhoneOutlined, MailOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <div className="header-content container">
            <div className="contact">
                <ul className="ul-contact">
                    <li>
                        <Link to="" title="Send a mail" data-placement="bottom">
                        <MailOutlined className="icon" />
                        info@primhex.com
                        </Link>
                        </li>
                    <li>
                    <Link to="08065606951" title="Give us a call" data-placement="bottom">
                        <PhoneOutlined className="icon" />
                        0806-560-6951
                        </Link>
                        </li>
                </ul>
            </div>
            <div className="recent-info">
                <span>Nigeria first and leading logistics support platform. 
                 
                     </span>
            </div>
        </div>
    )
}

export default Header
