import React from 'react'
import {
  PhoneOutlined, MailOutlined,
} from '@ant-design/icons';
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div>
        <div className="footer-wrapper container">
            <div className="footer1">
                <ul className="ul-products">
                <Link to="/">
                    <img
                        width={150}
                        className=""
                        alt='primhex logo'
                    src="./img/primhex-invert.png"
                    />
                    </Link>
                    <h3>Primhex Logistic Support</h3>
                    <li>
                        <PhoneOutlined className="footer-icon" />
                        (+234) 806 560 6951</li>
                    <li>
                        <MailOutlined className="footer-icon" />
                        Info@primhex.com</li>
                    {/* <li>
                        <WechatOutlined className="footer-icon" />
                        Chat with us</li> */}
                </ul>
            </div>
            <div className="footer1">
                <h2>Our Products</h2>
                <ul className="ul-products">
                    <li>
                        <Link to="/products">
                        Logistic User App
                        </Link>
                        </li>
                    <li>
                    <Link to="/products">
                        Rider User App
                        </Link>
                        </li>
                    <li>
                    <Link to="/products">
                        Merchant Dashboard
                        </Link>
                        </li>
                </ul>
            </div>
            <div className="footer1">
                <h2>Services</h2>
                <ul className="ul-products">
                    <li><Link to="/service">Bike Rentals</Link></li>
                    <li><Link to="/service">Shared Spapces</Link></li>
                    <li><Link to="/service">Web &amp; Mobile Apps</Link></li>
                    <li><Link to="/service">Consulting</Link></li>
                </ul>
            </div>
            <div className="footer1">
                <h2>Contact</h2>
                <div className="ul-products ul-contact">
                    <p>No 2, Yewa Road,
                    Ikorodu 104102, Lagos, Nigeria.</p>
                </div>
            </div>
            </div>
            <div className="footer-bottom container">
                <h2>Copyright ©2021 Primhex. All rights reserved. | &nbsp;
                <Link to="/terms-condition" target="_blank" className='termscondition'>Terms and Conditions</Link> &nbsp;| &nbsp;
                <Link to="/privacy-policy" target="_blank" className='termscondition'>Privacy Policy</Link></h2>
            </div>
        </div>
    )
}

export default Footer