import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Button, Row } from "antd";
import { Card } from "antd";
import { Link } from "react-router-dom";

function Products() {
  return (
    <div>
      <Header />
      <Navbar />
      {/*  */}
      <div className="image-content about-image">
        <img src="./img/about.jpg" alt="" />
        <div className="about-content container">
          <h3>Our Products</h3>
          <p>
            Coordinating the movement of supplies and materials is now a
            globalized process. Today, the business sector uses logistics as a
            term to describe the efficient flow and storage of goods
          </p>
        </div>
      </div>
      {/* What we do */}
      <div className="content-wrapper container">
        <div className="content-text pt-1">
          <h2>The Outstanding Brand</h2> 
          <p>
          Supply chains are unique networks between businesses that deal with the production, shipment, warehousing and delivery of products. These networks are very important to businesses as they largely affect sales and profits. However, without effective and well-organized logistics, supply chains can’t help your business gain a clear advantage over the competition.
          </p>
          <p>
          While a good marketing strategy can “open many doors” and attract customers, a reliable logistics service can help your business build and maintain a positive public image. Meanwhile, poorly organized logistics can lead to losing customers and decreased sales.
          </p>
          <Link to="/book-a-demo">
            <Button className="landing-page-cta-btn">Register Now</Button>
          </Link>
        </div>
        <div className="content-video">
          <img src="./img/product-video.jpg" alt="" />
        </div>
      </div>

      {/*  */}
      <div className="product-one  container" id="mobileApp">
        <div className="productOne-header">
          <h2>User Mobile App</h2>
          <p>
          You can now minimise follow-up phone calls. This user-friendly app has an enabled present time function of the rider’s whereabouts and the estimated time of reach and distance of the package. You can also fund your wallet with big brand i.e paystack, flutterwave to ensure you have a quick and efficient transactions on the app. We have in-app call to follow up conversations with the rider. With just the click of the button, you can set the pace!

          </p>
        </div>
        <div className="productOne-content">
          <div className="user-image">
            <img src="./img/user-app.png" alt="" />
          </div>
          <div className="user-content">
            <img src="./img/product-growth.png" alt="" />
            <h2>Order & Send Products Easily</h2>
            <p>
              Specially designed for users to seamlessly create/schedule orders,
              make payments and keep track of packages. A chatspace is also
              provided to keep users informed.
            </p>
            <p>
              Signup for our user app and enjoy the seamlessly advantages of sending and receiving orders.
            </p>
            <div className="pt-1">
              <Link to="/book-a-demo">
                <Button className="landing-page-cta-btn">Subscribe</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="product-one prd-two container">
        <div className="productOne-header">
          <h2>Merchant Dashboard</h2>
          <p>
          An overview of the whole entire workspace is now within reach. This elaborate description gives a rundown of your rider’s information, your business's financials and an assist chatbot will be at your service. This well secured platform is just what you need to fast track your business to the next level.
          A complete overview of inflows, monitors riders’ location at a given time, keeps tabs of customer’s requests all the while recording daily transactions and riders proceeds.

          </p>
        </div>
        <div className="productOne-content">
          <div className="user-image merchant-dashboard">
            <img src="./img/merchant-app.png" className="merchant-app" alt="" />
          </div>
          <div className="user-content merchant-product">
            <Row className="add-pad">
              <Card
                className="card-green pad1 card-product"
              >
                <img src="./img/optimize.png" className="merchant-app" alt="" />
                <p>Efficient &amp; Scalable</p>
              </Card>

              <Card className="card-white card-product" >
                <img src="./img/feature.png" className="merchant-app" alt="" />
                <p>Customize Application</p>
              </Card>
            </Row>
            <Row>
              <Card
                className="card-white pad1 card-product"
               
              >
                <img
                  src="./img/secure-payment.png"
                  className="merchant-app"
                  alt=""
                />
                <p>Secure Payment</p>
              </Card>
              <Card className="card-white card-product">
                <img src="./img/customer.png" className="merchant-app" alt="" />
                <p>Customer Support</p>
              </Card>
            </Row>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="product-one container">
        <div className="productOne-header ">
          <h2>Rider Mobile App</h2>
          <p>
          This part of our offerings provides the rider with the necessary information needed to dispatch goods.
          This will help to keep your busines fully running.
A full detailed description is made available for daily returns and benefits. Some of the features include: Distance covered per ride, Number of rides, Total sum of completed trips.

          </p>
        </div>
        <div className="productOne-content">
          <div className="user-content rider-user-content">
            <img src="./img/product-growth.png" alt="" />
            <h2>Keep Your Business Growth &amp; Sales</h2>
            <div></div>
            <p>
            Automated to inform riders of available hotspots, gives an estimated time of reach complete with detours and provides a daily set target.
            </p>
            <p>
            Signup for our rider app and enjoy the seamlessly advantages of delivery and making money as a rider.
            </p>
            <div className="pt-1">
              <Link to="/book-a-demo">
                <Button className="landing-page-cta-btn">Subscribe</Button>
              </Link>
            </div>
          </div>
          <div className="user-image">
            <img src="./img/rider-app.png" alt="" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Products;
