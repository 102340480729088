import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Button } from "antd";
import { Link } from "react-router-dom";
import "animate.css";
import Carousel from "../components/Carousel";

function Home() {
  return (
    <div>
      <Header />
      <Navbar />
      <Carousel />
      {/* <Home /> */}
      <section>
        <div className="primhex-body container">
          <h2 className="main-caption animate__animated animate__fadeInRight">
            Logistics Simplified & Demystified
          </h2>
          <p className="main-text animate__animated animate__fadeInRight">
            We implement, and control the movement and storage of goods within a
            supply chain
          </p>
          <div className="about-primhex">
            <div className="landing-content animate__animated animate__fadeInRight">
              <img src="./img/logistics.png" alt="" />
              <h2>Logistics</h2>
              <p>
                We have large haulage options for both interstate and intrastate travel. 
              </p>
            </div>
            <div className="landing-content animate__animated animate__fadeInDown">
              <img src="./img/delivery.png" alt="" />
              <h2>Delivery Company</h2>
              <p>
              We ensure billable services that are directly associated with a product
              are delivered.
              </p>
            </div>
            <div className="landing-content animate__animated animate__fadeInRight">
              <img src="./img/startup.png" alt="" />
              <h2>Registered Business</h2>
              <p>
              We create a business name that will go a long way toward the 
              success of your small business
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="primhex-main container">
          <div className="primhex-main-content">
            <h3 className="">About Us</h3>
            <h2 className="">
              Scale faster with access to all your business needs at affordable
              prices.
            </h2>
            <p>
              Coordinating the movement of supplies and materials is now a
              globalized process. Today, the business sector uses logistics as a
              term to describe the efficient flow and storage of goods from
              point of origin to the point of consumption.
            </p>
            <p>
              The supply chain is a vital part of this process. A proper supply
              chain may include transportation, shipping, receiving, storage,
            </p>
            {/* <p>
              Put your resources to efficient use with our rental plans,
              subscriptions and shared services.
            </p> */}
            <div className="pt-1">
              <Link to="/about">
              <Button className="landing-page-cta-btn">Learn More </Button>
              </Link>
            </div>
          </div>
          <div className="primhex-main-content">
            <img src="./img/more-about-us.jpg" className="" alt="" />
            {/* <AboutCarousel /> */}
          </div>
        </div>
      </section>

      <section>
        <div className="primhex-services container">
          <div className="primhex-services1 service-default">
            <h2>Our Services</h2>
            <p className="main-text">
              The best way to find yourself is to lose yourself in the service
              of others.
            </p>
          </div>
          <div className="primhex-core primhex-core-pad">
            <div className="service-features">
              <div className="service-feature-n1">
                <img src="./img/bicycle.png" alt="" />
              </div>
              <div className="service-feature-n2">
                <h2>Bike Rentals</h2>
                <p>
                  You can rent 5 fully registered bikes for the price of
                  purchasing one. We offer fully registered dispatch bikes at
                  competitive pricing! Who says you have to start small or grow
                  slowly.
                </p>
              </div>
            </div>
            <div className="service-features">
              {/* <img src="./img/work-space.png" alt="" /> */}
              <div className="service-feature-n1">
                <img src="./img/work-space.png" alt="" />
              </div>
              <div className="service-feature-n2">
                <h2>Shared Spaces</h2>
                <p>
                  Searching for a serene workspace? Look no further! Pitch your
                  tent at our newly renovated office space, complete with work
                  enabled furniture and a well secured parking lot. The shared
                  spaces open up a world of connections and endless
                  possibilities without having to break the bank.
                </p>
              </div>
            </div>
          </div>
          <div className="primhex-core">
            <div className="service-features">
              <div className="service-feature-n1">
                <img src="./img/booking.png" alt="" />
              </div>
              <div className="service-feature-n2">
                <h2>Web and Mobile Apps</h2>
                <p>
                  Bypass the developmental cost, time and technicalities and
                  enjoy an all round support solution. With experienced hands on
                  deck, we offer rich and user friendly functionalities.
                  Subscribe to our web and mobile applications today!
                </p>
              </div>
            </div>
            <div className="service-features">
              <div className="service-feature-n1">
                <img src="./img/consulting.png" alt="" />
              </div>
              <div className="service-feature-n2">
                <h2>Consulting</h2>
                <p>
                  Staying ahead is our watchword! <br />A team of initiatives
                  that sets you up with industry approved resources, empowers
                  you with proven winning strategies and insights, best
                  practices that gives you a competitive advantage, to jumpstart
                  your budding business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container yellow-label">
          <div className="row">
            <div className="col-md-12 employer-caption">
              <h2>
                Experience an efficient way to move your goods around the world.{" "}
              </h2>
              <p>
                We guarantee a pocket-friendly rates on all our services to any
                destination in the world.
              </p>
              <div className="cont-button">
                <div className="text-center pt-3 upgrade-button">
                <Link to="/contact" className="high" onclick="console.log('The link was clicked.')">
                  <button className="btn landing-page-cta-btn">Contact us</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="line-box-wrap">
            <div className="line-background"></div>
            <div className="line-background"></div>
            <div className="line-background"></div>
            <div className="line-background"></div>
            <div className="line-background"></div>
            <div className="line-background"></div>
            <div className="line-background"></div>
          </div>
          <img
            src="../images/employer/mask-group-1.svg"
            className="img-fluid cta-yellow-shape"
            alt=""
          />
        </div>
      </section>

      <section>
        <div className="content-wrapper home-space-content container">
          <div className="content-text">
            <h2>The Exclusive Brand</h2>
            <h3>Primhex is making success stories one company at a time.</h3>
            <p>
              Since its inception, logistics companies in Nigeria have taken
              advantage of primhex services to maximise resources, increase
              client base and ultimately drive profits up.
            </p>
            <p>With professionally organized logistics, businesses are able to answer short-time requirements. By choosing an experienced team of professionals, business entrepreneurs can ensure quick and safe shipping, warehousing and delivery of their products to customers.</p>
            <div className="pt-1">
            <Link to="/service">
              <Button className="landing-page-cta-btn">Register Now</Button>
              </Link>
            </div>
          </div>
          <div className="content-video">
            <img src="./img/exclusive-brand.jpg" alt="" />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Home;
