import React from 'react'
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function PrivacyPolicy() {
    return (
        <div>
        <Header />
      <Navbar />
        <div className='privacyPolicy container'>
            <h2>Privacy Policy</h2>
            <p>
            This Privacy Policy discloses the privacy practices for (www.primhex.com). 
            This privacy policy applies solely to information collected by this website. 
            It will inform you of the following: </p>
            <p>
              1. What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.</p>
              <p> 2. What choices are available to you regarding the use of your data.</p>
              <p> 3. The security procedures in place to protect the misuse of your information.</p>
              <p> 4. How you can correct any inaccuracies in the information.</p>
        </div>
      <Footer />
        </div>
    )
}

export default PrivacyPolicy
