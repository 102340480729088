import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { Button } from "antd";

function About() {
  return (
    <div>
      <Header />
      <Navbar />
      {/*  */}
      <div className="about-image">
        <img src="./img/about.jpg" alt="" />
        <div className="about-content container">
          <h3>About Us</h3>
          <p>
            The only way up is to accelerate through. We are a team of industry experts that prepare your business for the world at large. We take
            care of the nitty gritty, allowing you to focus on the frontlines.
          </p>
        </div>
      </div>
      {/*  */}
      <div className="who-content">
        <h2>Who we are</h2>
        <p>
        Coordinating the movement of supplies and materials is a globalised process and is rapidly growing in Nigeria. We are at the forefront of revolutionising this process to ensure providers of logistics services can be more effective and efficient.
        </p>
      </div>
      {/*  */}
      <div className="contact-block1 add-gap container">
        <div className="contact-content2 about1">
          <h2>We are Primhex</h2> 
          <p>
          Supporting your enterprise with our plethora of solutions ranging from the provision of equipment such as motorcycles to technological infrastructure such as web and mobile apps.
          </p>
          <p>
            As your No. 1 logistics support system, we help structure and map
            out your existing plan and identify ways to improve.
          </p>
          <p>
          With professionally organized logistics, businesses are able to answer short-time requirements. By choosing an experienced team of professionals, business entrepreneurs can ensure quick and safe shipping, warehousing and delivery of their products to customers.
          </p>
          <div className="pt-1">
            <Link to="/service">
              <Button className="landing-page-cta-btn add-space">Learn More</Button>
            </Link>
          </div>
        </div>
        <div className="contact-content">
          <img className="img" src="./img/about1.jpg" alt="" />
        </div>
      </div>
      {/*  */}
      <div className="mission-vision container">
        <div className="contact-content">
          <img className="img" src="./img/about2.jpg" alt="" />
        </div>
        <div className="about1 contact-content2">
          <h2>Our Mission &amp; Vision</h2>
          <p>
            To become the backbone of the logistics industry in Africa. <br />
            Primhex Logistics Support was established in 2020 with the strong
            basic belief that Client satisfaction would translate into long term
            business and growth; this has been our recipe for success.
          </p>
          <p>
            To develop and efficiently deploy services that meet identified
            needs. <br />
            We had a strong belief that we could build on our industry knowledge
            and improve in areas with shortfalls in the industry: Employee,
            customer and vendor loyalty. Our philosophy stands on one simple
            rule: CLIENT SATISFACTION.
          </p>
          <Link to="/contact">
          <Button className="landing-page-cta-btn add-space">Email Us</Button>
          </Link>
        </div>
      </div>
      {/* <div className="our-team container">
        <h2>Product 1</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        <div className="team-image"></div>
      </div> */}
      {/* <div className="brand-partners">
                <h2>Brand Partners</h2>
            </div> */}
      <Footer />
    </div>
  );
}

export default About;
